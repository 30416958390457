<template>
    <v-row>
        <v-dialog v-model="dialogDetalhe.status" persistent>
            <v-card min-width="300">
                <v-card-title>Detalhes do Histórico</v-card-title>
                <v-divider />
                <v-card-text>...</v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        elevation="0"
                        @click="dialogDetalhe.status = false"
                    >Fechar</v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAlteracao.status" persistent>
            <v-card min-width="300">
                <v-card-title>Alterar equipamento</v-card-title>
                <v-divider />
                <v-card-text>...</v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        elevation="0"
                        @click="dialogAlteracao.status = false"
                    >Fechar</v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRegistro.status" persistent>
            <v-card min-width="300">
                <v-card-title>Novo registro</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col>
                            <v-select
                                class="mb-n3"
                                label="Tipo de registro"
                                outlined
                                dense
                                :items="[]"
                            ></v-select>
                            <span
                                class="text-caption"
                            >* Selecione o tipo de registro acima para abrir o formulário de inclusão de registro</span>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        elevation="0"
                        @click="dialogRegistro.status = false"
                    >Fechar</v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-col cols="12" md="4">
            <v-card class="mb-3" dark color="primary">
                <v-list-item three-line>
                    <v-list-item-content>
                        <v-list-item-title class="text-h5">Nome equipamento</v-list-item-title>
                        <v-list-item-subtitle>Descrição do equipamento Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus nobis voluptatum at culpa, non facere!</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-avatar tile size="80" color="grey"></v-list-item-avatar>
                </v-list-item>
            </v-card>
            <v-card>
                <v-card-text>
                    <v-expansion-panels dark flat v-model="panel" multiple>
                        <v-expansion-panel class="mb-2">
                            <v-expansion-panel-header color="primary" class="text-h6">Valores</v-expansion-panel-header>
                            <v-divider class="white" />
                            <v-expansion-panel-content color="primary">
                                <v-row class="mt-2">
                                    <v-col cols="6" class="font-weight-bold mb-n4">Diária:</v-col>
                                    <v-col cols="6" class="mb-n4 text-end">R$ 100,00</v-col>
                                    <v-col cols="6" class="font-weight-bold mb-n4">Semanal:</v-col>
                                    <v-col cols="6" class="mb-n4 text-end">R$ 100,00</v-col>
                                    <v-col cols="6" class="font-weight-bold">Mensal:</v-col>
                                    <v-col cols="6" class="text-end">R$ 100,00</v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header color="primary" class="text-h6">Informações</v-expansion-panel-header>
                            <v-divider class="white" />
                            <v-expansion-panel-content color="primary">
                                <v-row class="mt-2">
                                    <v-col cols="6" class="font-weight-bold mb-n4">Data aquisição:</v-col>
                                    <v-col cols="6" class="mb-n4 text-end">23/03/2022</v-col>
                                    <v-col cols="6" class="font-weight-bold mb-n4">Custo aquisição:</v-col>
                                    <v-col cols="6" class="mb-n4 text-end">R$ 53,00</v-col>
                                    <v-col cols="6" class="font-weight-bold mb-n4">Custo reposição:</v-col>
                                    <v-col cols="6" class="mb-n4 text-end">R$ 780,00</v-col>
                                    <v-col cols="6" class="font-weight-bold mb-n4">Despesas:</v-col>
                                    <v-col cols="6" class="mb-n4 text-end">R$ 53,00</v-col>
                                    <v-col cols="6" class="font-weight-bold mb-n4">Faturamento:</v-col>
                                    <v-col cols="6" class="mb-n4 text-end">R$ 780,00</v-col>
                                    <v-col cols="6" class="font-weight-bold mb-n4">Ocupação:</v-col>
                                    <v-col cols="6" class="mb-n4 text-end">#######</v-col>
                                    <v-col cols="6" class="font-weight-bold mb-n4">Periodicidade:</v-col>
                                    <v-col cols="6" class="mb-n4 text-end">#######</v-col>
                                    <v-col cols="6" class="font-weight-bold">Status:</v-col>
                                    <v-col cols="6" class="text-end">#######</v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-spacer />
                    <v-btn elevation="0" color="primary" @click="dialogAlteracao.status = true">
                        <v-icon>mdi-pencil</v-icon>Alterar
                    </v-btn>
                    <v-btn elevation="0" color="primary" @click="dialogRegistro.status = true">
                        <v-icon>mdi-plus</v-icon>Registro
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-col cols="12" md="8">
            <v-card>
                <v-card-title>Histórico do equipamento</v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>Tipo</th>
                                <th>Data Inicio</th>
                                <th>Data Retorno</th>
                                <th>Valor</th>
                                <th>Cliente</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="n in 5" :key="n">
                                <td>########</td>
                                <td>########</td>
                                <td>########</td>
                                <td>########</td>
                                <td>########</td>
                                <td class="text-center">
                                    <v-btn
                                        fab
                                        color="primary"
                                        x-small
                                        elevation="0"
                                        @click="dialogDetalhe.status = true"
                                    >
                                        <v-icon>mdi-eye-plus-outline</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "AluguelEquipamentoListaGet",
    data: () => ({
        panel: [0],
        dialogDetalhe: { status: false },
        dialogAlteracao: { status: false },
        dialogRegistro: { status: false },
        dados: {
            produto: "Furadeira com impacto 1/2', FIV 1050, 127V VONDER",
            imagem: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2YAoseDnrCXdnsRmPR-IoM95CjYwrnAHXRXSLGwjZnfhFFjkPhOhxVWE7aPatohU-AmE&usqp=CAU",
            prazos: [
                { prazo: "Diária", valor: 100, desconto: 10 },
                { prazo: "Semanal", valor: 150, desconto: 15 },
                { prazo: "Quinzenal", valor: 200, desconto: 20 },
                { prazo: "Mensal", valor: 300, desconto: 30 },
            ],
            estoque: 5,
            reserva: 3,
            prazoSelecionado: "Diária",
            quantidade: 1,
        },
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit"]),
    },
    methods: {},
    created() {},
};
</script>

<style scoped>
</style>